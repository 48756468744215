* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
.logo{
  margin-right: 0px ;
  width: 9.375rem;
}

.start{
  margin-top: 0.625rem;
}
.header-image{
  width: 100%;
  padding-top: 100px;
  height: auto;
  object-fit: contain;
}
.Title {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  padding: auto;
}

.main-button{
 margin-top: 2rem;
 width: 12.5rem;
 height: 3.125rem;
 color: white;
 background-color: black;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 border-radius: 25px;
 font-size: 1.25rem;
 border: none;
 cursor: pointer;
}
.main-button:hover{
  background-color: grey;
}
.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-height: 100%;
  padding-top: 50px;
  overflow-y: auto;
  
}
.details{
  font-size: medium;
  margin-bottom: 10px;
}

.modal-content {
  
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  margin:  15% auto;
  padding: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.modal-content h2 {
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
}
.modal-content h3{
  margin-top: 0px;
  text-align: center;
}

.modal-content h6{
  margin: 25px 0;
  justify-content: left;
  font-size: 14px;
  font-weight: 400;
  text-align: center;

}

.modal-content form {
  display: flex;
  flex-direction: column;
  
}

.modal-content label {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: flex-start;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 10px;
  overflow-y: hidden;
}

.modal-content button {
  margin-top: 15px;
  padding: 10px auto;
  color: #ffffff;
  background-color: black;
  border-radius: 10px;
  cursor: pointer;
  display: flex; 
  width: 100px;
  height: 50px;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  border: none;
}

.modal-content button:hover {
  background-color: gray;
}
.sub-button{
  color: #ffffff;
  /* background-color: #000000; */
  /* background-color: rgb(84, 48, 32); */
  display: flex;
  justify-content: space-evenly;
}
#sub{
  color: white;
  background-color: black;
}
#sub:hover{
  background-color: grey;
}
#but{
  justify-content: center;
}
.Second-section {
  text-align: center;
  margin-top: 100px;
  padding: auto;
}

h5 {
  padding: auto;
  margin-top: 1.25rem;
  font-size: 1.125rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-weight: normal;
}

body {
  background-color: white;
  max-width: 100%;
  overflow-x: hidden;
  /* align-items: center; */
}
.main-logo-container{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.5);
}
.logo-grid {
  padding-top: 50px;
  display: flex ;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10%;
  column-gap: 30px;
}

.logo-container {
  width: 100%;
  animation: scroll 5s linear infinite;
}

.logo-image {
  max-width: 100%;
  
  
}
.logo-image img{
  filter: none;
  object-fit: contain;
  flex: none;
  width: 100px;
  max-width: 100px;
  height: 90px;
  box-shadow: 0 13px 100px #c7c7c780;
  border-radius: 8px;
  
}
@keyframes scroll {
  from{
    transform: translate(0);
  }
  to{
    transform: translateX(-100%);
  }
}

.ecom-background, .pl-background{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    gap: 40px; 
    margin-left: 5rem;
    /* align-items: center;  */
}
 
.ecom-item, .pl-item{
  padding: 10px;
  
}
.box-picture img{
  width: 100%;
  height: auto;
}
.ecom-text{
  text-align: left;
}
.ecom-text h2 , .pl-text h2{
   font-size: 20px;
   margin-bottom: 1rem;
   
}
.ecom-text p, .pl-text p{
  line-height: 1.5;
  justify-content: left;
  margin-bottom: 1rem;
}
.pl-text button, .ecom-background button{
  color: white;
  background-color: black;
  width: 6rem;
  height: 3rem;
  text-align: center;
  border-radius: 30px;
  justify-content: center;
  font-size: 14px;
  align-items: center;
 }

.ecom-pic{
   margin-left: 30px;
   padding: 5px;
   
}
.pl-pic{
  margin-right: 30px;
   padding: 5px;
}
.box-picture img{
  width: 100%;
  border-radius: 10px;  
}   
button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.faq-heading{
  letter-spacing: -.5px;
    margin-top: 10%;
    margin-bottom: 0;
    font-family: Lato, sans-serif;
    font-size: 44px;
    font-weight: 700;
    line-height: 115%;
    text-align: center;
    
}

.faq-subheading{
  text-align: center;
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
  margin-top: 10px;
  
}
.wrapper{
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.question{
  margin-top: 10px;
  margin-right: 5px;
  background-color: black;
  border-radius: 20px;
  
}
.arrow{
  padding-left: 5px;
}

.collapsible{
  max-width: 450px;
  overflow: hidden;
  font-weight: 500;
}
.collapsible input{
  display: none;
}

.collapsible label {
  position: relative;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1;
  align-self: center;
  align-content: center;
  align-items: center;
}

.collapsible label:after{
  content: '';
  position: relative;
  right: 15px;
  top: 15 px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}
.collapsible input:checked + label::after{
  transform: rotate(90deg);
}
 
.collapsible-text {
  max-height: 1px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.4;
  position: relative;
  top: -100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.collapsible input:checked ~ .collapsible-text{
  max-height: 300px;
  padding-bottom: 25px;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  opacity: 1;
  top: 0;
}

.collapsible-text p{
  padding-left: 15px;
  padding-right: 15px;
}

.collapsible2{
  max-width: 450px;
  overflow: hidden;
  font-weight: 500;
}
.collapsible2 input{
  display: none;
}

.collapsible2 label {
  position: relative;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1;
}

.collapsible2 label:after{
  content: '';
  position: relative;
  right: 15px;
  top: 15 px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}
.collapsible2 input:checked + label::after{
  transform: rotate(90deg);
}
 
.collapsible-text2 {
  max-height: 1px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.4;
  position: relative;
  top: -100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.collapsible2 input:checked ~ .collapsible-text2{
  max-height: 300px;
  padding-bottom: 25px;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  opacity: 1;
  top: 0;
}

.collapsible-text2 p{
  padding-left: 15px;
  padding-right: 15px;
}
.collapsible3{
  max-width: 450px;
  overflow: hidden;
  font-weight: 500;
}
.collapsible3 input{
  display: none;
}

.collapsible3 label {
  position: relative;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1;
}

.collapsible3 label:after{
  content: '';
  position: relative;
  right: 15px;
  top: 15 px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}
.collapsible3 input:checked + label::after{
  transform: rotate(90deg);
}
 
.collapsible-text3 {
  max-height: 1px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.4;
  position: relative;
  top: -100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.collapsible3 input:checked ~ .collapsible-text3{
  max-height: 300px;
  padding-bottom: 25px;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  opacity: 1;
  top: 0;
}

.collapsible-text3 p{
  padding-left: 15px;
  padding-right: 15px;
}
.collapsible4{
  max-width: 450px;
  overflow: hidden;
  font-weight: 500;
}
.collapsible4 input{
  display: none;
}

.collapsible4 label {
  position: relative;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1;
}

.collapsible4 label:after{
  content: '';
  position: relative;
  right: 15px;
  top: 15 px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}
.collapsible4 input:checked + label::after{
  transform: rotate(90deg);
}
 
.collapsible-text4 {
  max-height: 1px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.4;
  position: relative;
  top: -100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.collapsible4 input:checked ~ .collapsible-text4{
  max-height: 300px;
  padding-bottom: 25px;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  opacity: 1;
  top: 0;
}

.collapsible-text4 p{
  padding-left: 15px;
  padding-right: 15px;
}
.collapsible5{
  max-width: 450px;
  overflow: hidden;
  font-weight: 500;
}
.collapsible5 input{
  display: none;
}

.collapsible5 label {
  position: relative;
  font-weight: 600;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 4px;
  z-index: 1;
}

.collapsible5 label:after{
  content: '';
  position: relative;
  right: 15px;
  top: 15 px;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}
.collapsible5 input:checked + label::after{
  transform: rotate(90deg);
}
 
.collapsible-text5 {
  max-height: 1px;
  overflow: hidden;
  border-radius: 4px;
  line-height: 1.4;
  position: relative;
  top: -100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.collapsible5 input:checked ~ .collapsible-text5{
  max-height: 300px;
  padding-bottom: 25px;
  background: #fff;
  box-shadow: 0 5px 11px rgba(0, 0, 0, .1), 0 4px 11px rgba(0, 0, 0, .08);
  opacity: 1;
  top: 0;
}

.collapsible-text5 p{
  padding-left: 15px;
  padding-right: 15px;
}

.contact-form-container {
  max-width: 100%;
  background-color:white;
  margin-top: 2rem;
  justify-items: center;
  color: black;
  padding-bottom: 50px; 
  
}

.contact-form-container h2 {
  text-align: center;
  color: black;
  padding: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.contact-form label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
}

.submit-button {
  color: white;
  background-color: black;
  border: #d9e3df solid 1px;
  height: 2.5rem;
  font-size: 1rem;
}
.submit-button:hover{
  background-color: gray;
}
.error-message{
  color: red;
}
.copyright{
  background-color: #2e2c2c;
  color: white;
  height: 8rem;
  font-size: medium;
  text-align: center;
  padding: 2rem;
}


@media (max-width: 1440px) {
  .body{
    overflow-x: hidden;
  }
  .main-button {
    width: 11rem; /* Adjust button size */
  }
  .header-image {
    padding-top: 80px; /* Adjust padding */
  }
  
}

/* Max Width: 1280px */
@media (max-width: 1280px) {
  .body{
    overflow-x: hidden;
  }
  .main-button {
    width: 10rem; /* Adjust button size */
  }
  .pl-text,
  .ecom-text {
    max-width: 50%; /* Adjust text container width */
  }
}

/* Max Width: 1024px */
@media (max-width: 1024px) {
  .body{
    overflow-x: hidden;
  }
  .main-button {
    width: 9rem; /* Adjust button size */
  }
  .pl-text p,
  .ecom-text p {
    font-size: 1rem; /* Adjust paragraph size */
  }
  .pl-text,
  .ecom-text {
    flex-direction: column; /* Stack elements vertically */
    max-width: 90%; /* Adjust container width */
  }
}

/* Max Width: 800px */
@media (max-width: 800px) {
  .body{
    overflow-x: hidden;
  }
  .main-button {
    width: 8rem; /* Adjust button size */
  }
  
    .modal-content {
      width: 90%; /* Adjust width */
      padding: 1rem; /* Adjust padding */
    }
 
    .ecom-background, .pl-background { 
        grid-template-columns: 1fr; /* Stack items vertically */ 
        gap: 20px; /* Reduce the gap for smaller screens */ 
        margin-left: 2rem; /* Adjust the margin */ 
      } 
    .ecom-item, .pl-item { 
      padding: 5px; /* Adjust the padding for smaller screens */ 
    } 
    .ecom-text h2, .pl-text h2 { 
      font-size: 18px; /* Adjust font size for smaller screens */ 
    } 
    .ecom-text p, .pl-text p { 
      margin-bottom: 0.5rem; /* Adjust margin for smaller screens */ 
    } 
    .pl-text button, .ecom-background button { 
      width: 5rem; /* Adjust button width */ 
      height: 2.5rem; /* Adjust button height */ 
      font-size: 12px; /* Adjust font size */ 
    } 
     .pl-pic {
        width: 70%;
       margin: 10px 0;
       
} 
.ecom-pic{
  width: 70%;
  margin-left: 12rem; 
}
}

/* Max Width: 600px */
@media (max-width: 600px) {
  .body {
    font-size: 14px; /* Adjust base font size */
    overflow-x: hidden;
  }
  .main-button {
    width: 7rem; /* Adjust button size */

  }
  .ecom-background, .pl-background { 
    grid-template-columns: 1fr; 
    gap: 10px; 
    margin-left: 1rem; 
  } 
  .ecom-item, .pl-item { 
    padding: 5px; 
  } 
    .ecom-text h2, .pl-text h2 { 
      font-size: 16px; 
    } 
    .ecom-text p, .pl-text p { 
      margin-bottom: 0.5rem; 
    } 
  .pl-text button, .ecom-background button { 
    width: 5rem; 
    height: 2rem; 
    font-size: 12px; 
  } 
  .ecom-pic, .pl-pic { 
    margin: 5px 0;  
  }
  
  .modal-content {
      width: 95%; 
      padding: 0.75rem; 
    }
  
    .modal-content h2 {
      font-size: 1.5rem; 
    }
  
}

/* Max Width: 450px */
@media (max-width: 450px) {
  .body {
    font-size: 12px; /* Further adjust base font size */
    overflow-x: hidden;
  }
  .main-button {
    width: 6rem; /* Adjust button size */
    font-size: 18px;
  }
  
  .modal-content {
    width: 95%; /* Use full width */
    padding: 0.5rem; /* Adjust padding */
  }

  .modal-content h2 {
    font-size: 1.25rem; /* Adjust heading size */
  }

  .modal-content h6 {
    font-size: 12px; /* Adjust font size */
  }
}

/* Max Width: 380px */
@media (max-width: 380px) {
  .body {
    font-size: 10px; /* Further decrease font size */
    overflow-x: hidden;
  }
  .main-button {
    width: 6rem; /* Adjust button size */
  }
  .modal-content {
    width: 95%; /* Use full width */
    padding: 0.5rem; /* Keep padding */
  }

  .modal-content h2 {
    font-size: 1.125rem; /* Further adjust heading size */
  }

  .modal-content h6 {
    font-size: 12px; /* Keep font size */
  }

  .modal-content button {
    padding: 8px; /* Adjust button padding */
  }
}
